










import { BuchListeModel } from "@/models/BuchListeModel";
import { catVue } from "@/util/logging";
import Vue from "vue";
export default Vue.extend({
   // Component Name
   name: "Buchlisten-Liste",

   data() {
      return {
         // "The Data"
         liste: new Array<BuchListeModel>(),
      };
   },

   methods: {
      async fetch() {
         this.$store.commit("changeAppLoadingState", true, {
            root: true,
         });

         let temp = await BuchListeModel.alle();
         for (let index = 0; index < temp.length; index++) {
            this.liste.push(temp[index]);
         }

         this.$store.commit("changeAppLoadingState", false, {
            root: true,
         });
      },
   },

   created() {
      catVue.info(`${this.$options.name}:: CREATED... `);
   },
   mounted() {
      catVue.info(`${this.$options.name}:: MOUNTED... `);

      this.fetch();
   },
});
